<template>
  <div>
    <el-card class="searchContainer">
      <el-row :gutter="20">
        <el-col :span="8">
          <span class="searchText">商家id</span>
          <el-input class="searchInput" placeholder="商家id" v-model.trim="searchValue.businessId" clearable>
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">操作详细内容</span>
          <el-input class="searchInput" placeholder="操作详细内容" v-model.trim="searchValue.content" clearable>
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">操作人会员ID</span>
          <el-input class="searchInput" placeholder="操作人会员ID" v-model.trim="searchValue.userId" clearable>
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin: 20px 0">
        <el-col :span="8">
          <span class="searchText">操作人会员名称</span>
          <el-input class="searchInput" placeholder="操作人会员名称" v-model.trim="searchValue.userName" clearable>
          </el-input>
        </el-col>
        <el-col :span="16">
          <span class="searchText">操作时间范围</span>
          <el-date-picker
            v-model="Time"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8" :offset="16">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <div class="tableContainer">
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <div>请求类型 :{{ props.row.method }}</div>
            <div>请求接口地址 :{{ props.row.rqeustUrl }}</div>

            <div>请求接口body参数 :{{ props.row.requestBody ? props.row.requestBody : '无' }}</div>

            <div>请求接口url参数 : {{ props.row.requestParam ? props.row.requestParam : '无' }}</div>

            <div>请求响应结果: {{ props.row.responseBody }}</div>
            <div>租户id: {{ props.row.tenantId }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="businessId" label="商家id"> </el-table-column>
        <el-table-column align="center" prop="content" label=" 操作详细内容"> </el-table-column>
        <el-table-column align="center" prop="userId" label="商家操作人会员ID"> </el-table-column>

        <el-table-column align="center" prop="userName" label="商家操作人会员名称"> </el-table-column>
        <el-table-column align="center" prop="ip" label=" ip"> </el-table-column>

        <el-table-column align="center" label="操作时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// 引入分页
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      searchValue: {
        businessId: '',
        content: '',
        createTimeEnd: '',
        createTimeStart: '',
        userId: '',
        userName: ''
      },
      list: [],
      Time: []
    }
  },
  methods: {
    // 重置搜索
    resetSearch() {
      this.searchValue = {
        businessId: '',
        content: '',
        createTimeEnd: '',
        createTimeStart: '',
        userId: '',
        userName: ''
      }
      this.Time = []
      this.getList()
    },
    // 请求后端获取退款原因列表
    getList(val) {
      this.loading = true
      this.searchValue.page = val === true ? this.currentPage : 1

      if (this.Time) {
        this.searchValue.createTimeStart = this.Time[0]
        this.searchValue.createTimeEnd = this.Time[1]
      } else {
        this.searchValue.createTimeStart = ''
        this.searchValue.createTimeEnd = ''
      }
      this.$http
        .get('/boom-center-analyse-service/audit/log/business', {
          params: {
            size: this.pageSize,
            ...this.searchValue
          }
        })
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped lang="less"></style>
