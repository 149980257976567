let components = {}
const moduleList = require.context('./', false, /\.vue$/)
moduleList.keys().forEach(fileName => {
  let moduleName = fileName.substring(2, fileName.length - 4)
  moduleName = 'com' + moduleName.substring(0, 1).toUpperCase() + moduleName.substring(1, moduleName.length)
  components[moduleName] = moduleList(fileName).default
})

export default {
  components
}
