<template>
  <div>
    <el-card class="editContainer">
      <span
        v-for="tab in tabs"
        :key="tab.componentName"
        class="componentTab"
        :class="{ active: currentTab === tab.componentName }"
        @click="tabName(tab)"
      >
        {{ tab.tabName }}
      </span>

      <transition name="slide-right">
        <component :is="currentTabComponent" class="tab"></component>
      </transition>
    </el-card>
  </div>
</template>

<script>
import comMixin from './__com__/index'

export default {
  mixins: [comMixin],
  data() {
    return {
      currentTab: 'UserOperationLog',
      tabs: [
        {
          componentName: 'UserOperationLog',
          tabName: '用户操作记录'
        },
        {
          componentName: 'UserLoginLog',
          tabName: '登录记录'
        },
        {
          componentName: 'BusinessUserLog',
          tabName: '商家用户操作'
        }
      ]
    }
  },
  methods: {
    tabName(tab) {
      this.currentTab = tab.componentName
    }
  },
  computed: {
    currentTabComponent: function() {
      return 'com' + this.currentTab
    }
  }
}
</script>

<style lang="less" scoped>
.editContainer {
  color: @color-primary;
  margin: @container-margin;
  min-height: 80%;
  .componentTab {
    margin: 0 20px;
  }
  .active {
    color: @color-primary-select;
    font-weight: bold;
  }
  .tab {
    margin-top: 40px;
  }
}
</style>
