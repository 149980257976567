<template>
  <div>
    <el-card class="searchContainer">
      <el-row :gutter="20">
        <el-col :span="8">
          <span class="searchText">登录IP地址</span>
          <el-input class="searchInput" placeholder="登录IP地址" v-model.trim="searchValue.ipAddr" clearable>
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">系统用户ID</span>
          <el-input class="searchInput" placeholder="系统用户ID" v-model.trim="searchValue.sysUserId" clearable>
          </el-input>
        </el-col>

        <el-col :span="8">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <div class="tableContainer">
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="id" label="ID"> </el-table-column>
        <el-table-column align="center" prop="ipAddr" label="登录IP地址"> </el-table-column>
        <el-table-column align="center" prop="sysUserId" label="系统用户ID"> </el-table-column>
        <el-table-column align="center" prop="sysUserName" label="系统用户名称"> </el-table-column>
        <el-table-column align="center" prop="userAgent" label="浏览器登录类型"> </el-table-column>
        <el-table-column align="center" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// 引入分页
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      searchValue: { ipAddr: '', sysUserId: '' },
      list: []
    }
  },
  methods: {
    // 重置搜索
    resetSearch() {
      this.searchValue = {
        ipAddr: '',
        sysUserId: ''
      }
      this.getList()
    },
    // 请求后端获取退款原因列表
    getList(val) {
      this.loading = true
      this.searchValue.page = val === true ? this.currentPage : 1
      this.$api.base
        .base('getSysUserLoginLog', {
          params: {
            size: this.pageSize,
            ...this.searchValue
          }
        })
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style scoped lang="less">
.refundDialog {
  .el-input-number,
  .el-input {
    width: 200px;
  }
}
</style>
